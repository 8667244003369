import type { SVGProps } from 'react';

export function CHANGEPASSWORD(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C2.44772 7 2 7.44772 2 8V16C2 16.5523 2.44772 17 3 17H15C15.5523 17 16 16.5523 16 16V8C16 7.44772 15.5523 7 15 7H3ZM11 10.5C11 11.1531 10.5826 11.7087 10 11.9146V14H9V11.9146C8.4174 11.7087 8 11.1531 8 10.5C8 9.67157 8.67157 9 9.5 9C10.3284 9 11 9.67157 11 10.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7V5C11 3.89543 10.1046 3 9 3C7.89543 3 7 3.89543 7 5V7H5V5C5 2.79086 6.79086 1 9 1C11.2091 1 13 2.79086 13 5V7H11Z"
        fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}
