import { MaterialSymbolsHeadphones } from '@/assets/icons/mine/MaterialSymbolsHeadphones';
import { MaterialSymbolsSettings } from '@/assets/icons/mine/MaterialSymbolsSettings';
import BuildDate from '@/buildDate.json';
import BaseTopNav from '@/components/base/baseTopNav';
import PageEnum from '@/enums/pageEnum';
import { MAX_RECONNECT_COUNT } from '@/models/socket';
import { cn } from '@/utils';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import MineCardList from './components/MineCardList';
import MineTopUser from './components/MineTopUser';
import { useMemo } from 'react';
import BaseBadge from '@/components/base/baseBadge';

/**
 * 我的模块主页面
 * */
export default function Mine() {
  // 基础定义
  const { t } = useTranslation();
  const { customerServiceMessageUNREAD, c2cServiceMessageUNREAD } = useModel('imSocket') || {};
  const { gotoGlobalCustomerService, imGroupList } = useModel('imSocket');
  const { reconnectCount, version } = useModel('socket');
  const unReadCount = useMemo(() => {
    return imGroupList?.reduce?.((acc: any, cur = {} as any) => {
      if (cur?.type === 5) {
        return acc + (customerServiceMessageUNREAD === false ? cur?.unreadCount : +customerServiceMessageUNREAD);
      }
      return acc + (c2cServiceMessageUNREAD?.[cur?.groupSign] === undefined ? cur?.unreadCount : c2cServiceMessageUNREAD?.[cur?.groupSign]);
    }, 0);
  }, [imGroupList, customerServiceMessageUNREAD, c2cServiceMessageUNREAD]);


  return (
    <div>
      <div>
        {/* 头像，名称，刷新按钮 */}
        <div>
          <BaseTopNav
            title={t('个人中心')}
            rightNode={
              <div className="w-full !h-[60px] flex items-center justify-end pr-2">
                <div className="p-2" onClick={() => {
                  gotoGlobalCustomerService();
                }}>
                  {/* 客服 icon */}
                  <BaseBadge
                    content={+unReadCount}
                  >
                    <MaterialSymbolsHeadphones
                      width={16}
                      height={16}
                      className=" cursor-pointer   box-content"
                    />
                  </BaseBadge>
                </div>

                <MaterialSymbolsSettings
                  className=" cursor-pointe p-2 box-content"
                  width={20}
                  height={20}
                  onClick={() => {
                    history.push(PageEnum.SETTINGS);
                  }}
                />
              </div>
            }
          />
          {/* 头部卡片结束 */}
        </div>

        {/* //总览 */}
        <div className="pt-1">
          <MineTopUser />
          <div className="mt-4 rounded-[25px 25px 0 0]">
            <MineCardList />
          </div>
        </div>
        <div className="text-center fixed bottom-3 left-1/2 text-auxiliaryTextColor text-xs -translate-x-1/2">
          <div className="pb-2 flex items-center gap-2">
            <div
              className={cn(`w-2 h-2 rounded-full`, {
                'text-errorColor': reconnectCount > MAX_RECONNECT_COUNT,
                'bg-successColor': reconnectCount <= MAX_RECONNECT_COUNT,
              })}
            ></div>
            {reconnectCount > 5 ? t('网络异常') : t('网络正常')}
          </div>
          <div>
            {version?.version ? `${version?.version}-` : ''}
            {BuildDate.buildDate}
          </div>
        </div>
      </div>
    </div>
  );
}
