import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import { VerifiedStatesEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { FormatUtils } from '@/utils/format';
import { Divider, Image } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { useTranslation } from 'react-i18next';

export default function TopUser() {
  const { t } = useTranslation();
  const { requestUserInfo, user } = useModel('user') || {};
  useMount(() => {
    requestUserInfo?.({ noFund: true });
  });
  // 在用户信息周围创建一个布局，包括用户头像和认证状态。
  return (
    <>
      <div className="flex mt-10 cursor-pointer items-center px-4 w-full justify-between">
        <div
          className="flex items-center w-full"
          onClick={(e) => {
            e.stopPropagation();
            history.push(PageEnum.CHANGEUSERINFORMATION);
          }}
        >
          <div>
            {user?.avatar ? (
              <Image
                className="w-14 h-14 flex-shrink-0 rounded-full object-cover"
                src={user?.avatar}
              />
            ) : (
              <Image
                className="w-14 h-14 flex-shrink-0 rounded-full object-cover"
                src={require('@/assets/img/defaultAvatar.png')}
              />
            )}
          </div>

          <div className="ml-2  flex flex-col justify-between text-[12px] my-1">
            <div className="text-backContrastColor text-base">{user?.name}</div>
            <div
              className="flex items-center gap-1 text-foreground py-[2px]"
              onClick={(e) => {
                FormatUtils.copyText(user?.account);
                e.stopPropagation();
              }}
            >
              <div className="flex items-center gap-1">
                <span>UID:</span>
                <span>{user?.account}</span>
              </div>
              <PhCopyFill className="text-primary" />
            </div>
            <div className="flex items-center mt-[2px] text-[14px]">
              {user?.realnameStatus === VerifiedStatesEnum.REAL && (
                <span className="bg-[#2DB88033] text-[14px] text-[#2DB880] flex items-center mr-1 py-1 px-2 rounded-[20px]">
                  <img
                    src={require('@/assets/img/user/REAL.png')}
                    className="w-[17px] mr-1 "
                    alt=""
                  />
                  {t('已认证')}
                </span>
              )}
              {user?.realnameStatus === VerifiedStatesEnum.UNDER && (
                <span className="bg-[#EE445633] text-[#EE4456]  flex items-center  mr-1 py-1 px-2 rounded-[20px]">
                  <img
                    src={require('@/assets/img/user/UNREAL.png')}
                    className="w-[16px] mr-1 "
                    alt=""
                  />
                  {t('审核中')}
                </span>
              )}

              {user?.realnameStatus === VerifiedStatesEnum.UNREAL && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(PageEnum.VERIFIED);
                  }}
                  className="bg-[#EE445633]  text-[#EE4456] flex items-center mr-1  py-1 px-2 rounded-[20px]"
                >
                  <img
                    src={require('@/assets/img/user/UNREAL.png')}
                    className="w-[16px] mr-1 "
                    alt=""
                  />
                  {t('未认证')}
                </span>
              )}

              {+user?.reservedField1 < 1 && (
                <span
                  className="bg-[#CBCBCB20] light:bg-backgroundAuxiliaryColor text-[#CBCBCB] ml-2 py-1 px-2 rounded-[20px] flex items-center"
                  onClick={(e) => {
                    history.push(PageEnum.APPLY_VIP);
                    e.stopPropagation();
                  }}
                >
                  <img
                    src={require('@/assets/img/user/user.png')}
                    className="w-[16px] mr-1 "
                    alt=""
                  />
                  {t('普通用户')}
                </span>
              )}

              {+user?.reservedField1 >= 1 && (
                <div className="flex items-center relative bg-[#D2B06C33] text-[#D2B06C] ml-2 py-1 px-2 rounded-[20px]">
                  <img
                    src={require('@/assets/img/user/vip.png')}
                    alt="VIP Logo"
                    className="w-[16px] mr-1 "
                  />
                  <div className="">
                    VIP
                    <span className="ml-1">{+user?.reservedField1}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <PhCaretRight />
      </div>
      <Divider className="my-6 !bg-backgroundAuxiliaryColor" />
    </>
  );
}
