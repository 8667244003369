import type { SVGProps } from 'react';

export function MESSAGE(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9324 13.9149C15.9034 13.2753 15.7339 12.65 15.4358 12.0834C15.1377 11.5168 14.7184 11.0228 14.2077 10.6367V7.01978C14.2077 4.93066 12.7126 3.17228 10.7371 2.75716V2.67391C10.7371 1.72103 9.95525 0.941406 9.00012 0.941406C8.045 0.941406 7.26312 1.72103 7.26312 2.67503V2.75828C5.28762 3.17228 3.7925 4.92953 3.7925 7.01978V10.6378C3.28172 11.0238 2.86235 11.5178 2.56426 12.0844C2.26616 12.651 2.09668 13.2764 2.06787 13.916H3.7925V13.9273H14.2077V13.916H15.9324V13.9149Z"
        fill="currentColor"
      />
      <path
        d="M9.0818 18H8.9182C8.2025 17.9984 7.51174 17.6931 6.97478 17.1411C6.43783 16.5891 6.09136 15.8281 6 15H12C11.8185 16.6848 10.5766 18 9.0818 18Z"
        fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}
