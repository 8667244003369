import type { SVGProps } from 'react';

export function CHANGEPAYMENTPASSWORD(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 1.7998H0.9V16.1998H0V1.7998ZM1.8 1.7998H2.7V16.1998H1.8V1.7998ZM3.6 1.7998H6.3V16.1998H3.6V1.7998ZM7.2 1.7998H8.1V16.1998H7.2V1.7998ZM9 1.7998H10.8V16.1998H9V1.7998ZM11.7 1.7998H13.5V16.1998H11.7V1.7998Z"
        fill="currentColor"
      />
      <path
        d="M17.9999 1.7998H14.3999V16.1998H17.9999V1.7998Z"
        fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}
