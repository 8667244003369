import type { SVGProps } from 'react';

export function VERIFIED(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_211_2374)">
        <path
          d="M5.14282 1.28564H16.7143V7.71422"
          stroke="#1B71EB"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3.85693C0.447715 3.85693 0 4.30465 0 4.85693V13.7855C0 14.3378 0.447715 14.7855 1 14.7855H13.1429C13.6951 14.7855 14.1429 14.3378 14.1429 13.7855V4.85693C14.1429 4.30465 13.6951 3.85693 13.1429 3.85693H1ZM9.64286 6.42836C9.28782 6.42836 9 6.71618 9 7.07122C9 7.42626 9.28782 7.71408 9.64286 7.71408H11.5714C11.9265 7.71408 12.2143 7.42626 12.2143 7.07122C12.2143 6.71618 11.9265 6.42836 11.5714 6.42836H9.64286ZM9 9.64265C9 9.28761 9.28782 8.99979 9.64286 8.99979H11.5714C11.9265 8.99979 12.2143 9.28761 12.2143 9.64265C12.2143 9.99769 11.9265 10.2855 11.5714 10.2855H9.64286C9.28782 10.2855 9 9.99769 9 9.64265ZM3.2116 7.62719C3.25455 6.98863 3.78064 6.46813 4.41948 6.43056C5.16567 6.38764 5.7884 6.9779 5.78303 7.71841C5.78303 8.42673 5.20862 9.00626 4.49463 9.00626C3.7538 9.00626 3.16329 8.37843 3.2116 7.62719ZM1.92857 10.7127C1.92857 9.85409 3.64107 9.42481 4.5 9.42481C5.35893 9.42481 7.07143 9.85409 7.07143 10.7073V11.3566C7.07143 11.4746 6.9748 11.5712 6.8567 11.5712H2.1433C2.0252 11.5712 1.92857 11.4746 1.92857 11.3566V10.7127Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_211_2374">
          <rect width="18" height="18" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
}
