import { Bank } from '@/assets/icons/comm/Bank';
import { CHANGEPASSWORD } from '@/assets/icons/comm/CHANGEPASSWORD';
import { CHANGEPAYMENTPASSWORD } from '@/assets/icons/comm/CHANGEPAYMENTPASSWORD';
import { INVITE } from '@/assets/icons/comm/INVITE';
import { MESSAGE } from '@/assets/icons/comm/MESSAGE';
import { VERIFIED } from '@/assets/icons/comm/VERIFIED';
import { VIP } from '@/assets/icons/comm/VIP';
import PageEnum from '@/enums/pageEnum';
import RechargeJumpModal from '@/pages/funds/index/components/RechargeJumpModal';
import { Button, Divider } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { School } from '@/assets/icons/comm/School';

export default function CardList() {
  const { t } = useTranslation();

  const defaultMenuList = [
    {
      key: t('返佣'),
      icon: INVITE,
      path: PageEnum.INVITE,
    },
    // {
    //   key: t('充值'),
    //   icon: TopUp,
    //   path: PageEnum.TopUp,
    // },
    // {
    //   key: t('提现'),
    //   icon: WITHDRAW,
    //   path: PageEnum.WITHDRAW,
    // },
    // {
    //   key: t('AI理财'),
    //   icon: AITRADE,
    //   path: PageEnum.AITRADE,
    // },
    {
      key: t('钱包'),
      icon: Bank,
      path: PageEnum.BANK,
    },
    {
      key: t('VIP'),
      icon: VIP,
      path: PageEnum.APPLY_VIP,
    },
    {
      key: t('学院'),
      icon: School,
      path: PageEnum.COLLEGE_LIST,
    },
    {
      key: t('消息通知'),
      icon: MESSAGE,
      path: PageEnum.MESSAGE,
    },
    {
      key: t('支付密码'),
      icon: CHANGEPAYMENTPASSWORD,
      path: PageEnum.CHANGEPAYMENTPASSWORD,
    },
    {
      key: t('登录密码'),
      icon: CHANGEPASSWORD,
      path: PageEnum.CHANGEPASSWORD,
    },
  ];

  const { logOut, isRealname } = useModel('user');
  const { getShowMenuList, menuList, getMenuListReq } = useModel('appInfo');

  const [cards, setCard] = useState(getShowMenuList(defaultMenuList));
  useEffect(() => {
    let _card = cards;
    if (!menuList) {
      getMenuListReq();
    }
    if (!isRealname) {
      _card.splice(_card.length - 1, 0, {
        key: t('实名认证'),
        icon: VERIFIED,
        path: PageEnum.VERIFIED,
      });
    }
    setCard(_card);
  }, []);


  

  return (
    <div>
      <div className="px-4">
        <div className="text-backgroundTextColor text-base mb-6">
          {/* {t('功能服务')} */}
        </div>
        <div className="grid cursor-pointer grid-cols-4 gap-y-[40px] gap-x-[20px]">
          {cards?.map((item: any) => (
            // 为每个卡片项渲染一个div，点击会跳转到对应的path。
            <>
              {item.path !== PageEnum.TopUp && (
                <div
                  className="w-full  flex flex-col justify-start items-center"
                  onClick={() => {
                    history.push(item.path);
                  }}
                  key={item.key}
                >
                  <item.icon className="text-iconColor" />
                  <span className="mt-2 text-center text-xxs">{item.key}</span>
                </div>
              )}
              {item.path === PageEnum.TopUp && (
                <RechargeJumpModal>
                  <div
                    className="w-full  flex flex-col justify-center items-center"
                    key={item.key}
                  >
                    <item.icon className="text-iconColor" />
                    <span className="mt-2 text-center text-xxs">
                      {item.key}
                    </span>
                  </div>
                </RechargeJumpModal>
              )}
            </>
          ))}
        </div>
      </div>
      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />

      <div className="w-full px-4 pb-2 flex justify-center">
        <Button
          className="w-full mx-auto rounded-md mt-4 bg-backgroundAuxiliaryColor h-[45px]"
          onClick={async () => {
            localStorage.setItem('APP_ARRANGEMENT_I18N', ''); //清理缓存

            if ('serviceWorker' in navigator) {
              const registrations =
                await navigator.serviceWorker.getRegistrations();
              if (registrations.length > 0) {
                for (let registration of registrations) {
                  await registration.unregister();
                  console.log('Service Worker 已注销');
                }
              } else {
                console.log('没有注册的 Service Worker');
              }
            } else {
              console.log('Service Worker 不受支持');
            }

            window.location.reload();
          }}
        >
          {t('清理缓存')}
        </Button>
      </div>

      <div className="w-full px-4 mt-3.5 pb-4 flex justify-center">
        <Button
          className="w-full mx-auto rounded-md mt-4 bg-primary text-primaryButtonTextColor h-[45px]"
          onClick={() => {
            logOut();
          }}
        >
          {t('退出登录')}
        </Button>
      </div>
    </div>
  );
}
