import type { SVGProps } from 'react';

export function School(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="15" y="6" width="3" height="6" fill="hsl(var(--nextui-primary)" />
      <path
        d="M17.2395 6.4465L16.1422 6.9405C12.2976 8.60655 11.0652 9.1244 10.2125 9.52865C9.35986 9.93289 8.7459 9.93086 7.90028 9.59596C7.05455 9.26099 3.02885 7.71685 1.02991 6.71145C-0.303994 6.04067 -0.389883 5.6152 1.05279 5.04589C2.9356 4.29416 6.04754 3.09027 7.69708 2.43322C8.6735 2.01807 9.18985 1.79143 10.0861 2.26484C11.6865 2.95936 15.3434 4.40158 17.1617 5.19064C18.7446 5.91664 17.6805 6.15541 17.2395 6.4465ZM10.3979 10.7674C11.3277 10.3642 12.5813 9.69762 13.9482 9.08214V14.0236C13.9482 14.0236 12.1804 16 9.07269 16C5.72587 16 3.91803 14.0236 3.91803 14.0236V9.4112C4.97303 9.86294 6.15774 10.2516 7.59144 10.7674C8.47506 11.0978 9.59633 11.2121 10.3979 10.7674Z"
        fill="currentColor" />
    </svg>

  );
}
